import DataHandler from "../handler/DataHandler";
import loadExternal from "../utils/LoadExternal";
import Logger from "../utils/Logger";
import {DRONA_PLAYER_LIB_REGION_BASED_URL} from "../constants/PlayerConstants";
import AuthTokenProvider from "../gateway/AuthTokenProvider";
import FeatureManager from "../handler/FeatureManager";
import PUMAMetricHelper from "./PUMAMetricHelper";
const dolphinBus = new BroadcastChannel('DOLPHIN_BUS');

let trainingAvailable = false;

function playerEventListener (message) {
    try {
        const messageData = JSON.parse(message.data);
        if (messageData && messageData.namespace === 'IATPInfoMessage') {
            const target = document.getElementById('iat-player-container');
            if (messageData.messageType === 'BarcodeScanDisabled' || messageData.messageType === 'PlayerShown') {
                dolphinBus.postMessage("disableScan");
                target.style.pointerEvents = "inherit";
            } else if (messageData.messageType === 'PlayerHidden'
                || messageData.messageType === 'TrainingEnd' || messageData.messageType === 'BarcodeScanEnabled') {
                target.style.pointerEvents = "none";
                dolphinBus.postMessage("enableScan");
            } else if (messageData.messageType === 'TrainingStart') {
                trainingAvailable = true;
            }
        }
    } catch (JSONParseException) {
        console.log("JSON parse exception");
    }
}

export default {
    init(processPath, versionNumber, stage, region) {
        return new Promise((resolve) => {
            if (versionNumber && stage && region && FeatureManager.isFeatureEnabled(FeatureManager.Features.DRONA_ENABLED)) {
                const URL = DRONA_PLAYER_LIB_REGION_BASED_URL[stage][region] + versionNumber + "/main.js";
                loadExternal(URL, (status) => {
                    if(status) {
                        if(window.DolphinPlayer) {
                            window.DolphinPlayer.initialize(processPath, stage, region, DataHandler.getUserEmployeeId(),
                                DataHandler.getUserEmployeeLogin(), DataHandler.getUserLocale(), DataHandler.getStationCode(),
                                new AuthTokenProvider())
                                .then(resolve);
                            window.addEventListener('message', playerEventListener);
                        } else {
                            Logger.log.info('Player not found');
                        }
                    } else Logger.log.error('DRONA PLAYER not initialized, unable to download library');
                });
            } else {
                Logger.log.info('Drona library not loaded, parameters missing or Drona not enabled for the Station');
            }
        });
    },
    terminate() {
        return new Promise((resolve) => {
            if (window.DolphinPlayer) {
                window.DolphinPlayer.terminate().then(resolve(true));
            } else {
                Logger.log.info("Player not found");
                resolve(false);
            }
        });
    },
    sendMessage(event, state) {
        const startTime = Date.now();
        try {
            if (window.DolphinPlayer) {
                const messageId = window.DolphinPlayer.sendAppMessage(event, state);
                PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, "UmbrellaPlayer-SendMessage");
                Logger.log.info("Message from Player " + messageId);
            } else {
                Logger.log.info("Player not found");
            }
        } catch(error) {
            PUMAMetricHelper.publishLatencyToDolphinCSM(startTime, "UmbrellaPlayer-SendMessage");
            Logger.log.info("Exception Sending Player App Message" + error);
        }
    },
    isTrainingAvailable(){
        return trainingAvailable;
    }
}
